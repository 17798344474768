import React from 'react';

import LangSelect from './langselect';

const NavBar = ({
    logo,
    locale,
    languages,
    handleLanguageChange,
    language,
}) => {
    return (
        <nav className="nav has-shadow">
            <div className="container">
                <div className="nav-left">
                    <a className="nav-item">
                        <img src={logo} alt={locale.logoAlt} />
                    </a>
                </div>
                <div className="nav-right">
                    <span className="nav-item nav-item-lang-select">
                        <strong>{locale.dropdownLangLabel}:</strong>
                    </span>
                    <span className="nav-item nav-item-lang-select">
                        <LangSelect
                            className="lang-select"
                            locale={locale}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            language={language}
                        />
                    </span>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
