import React from 'react';

const Footer = ({ locale, logo, logoTapio, logoSitowise }) => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="content has-text-centered">
                    <p className="justify-text">{locale.footerContent}</p>
                    <figure className="image is-128x128 image-inline logo-footer1">
                        <img src={logo} alt={locale.logoAlt} />
                    </figure>
                    <figure className="image is-128x128 image-inline logo-footer2">
                        <img src={logoTapio} alt={locale.logo1Alt} />
                    </figure>
                    <figure className="image is-128x128 image-inline logo-footer3">
                        <img src={logoSitowise} alt={locale.logo2Alt} />
                    </figure>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
