import React from 'react';

const Hero = ({ locale }) => {
    return (
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-mobile">
                        <div className="column">
                            <h1 className="title title-hero">
                                {locale.heroTitle}
                            </h1>
                        </div>
                    </div>

                    <div className="columns is-mobile">
                        <div className="column">
                            <p className="justify-text">{locale.heroText1}</p>
                        </div>
                    </div>
                    <div className="columns is-mobile">
                        <div className="column">
                            <p className="justify-text">
                                {locale.heroText2}
                                <a target="_blank" href={locale.heroLinkUrl}>
                                    {locale.heroLinkText}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
