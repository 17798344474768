// React imports
import React, { Component } from 'react';

// App imports
import Utils from './utils';

class CalcField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };
    }

    onInput(event) {
        event.preventDefault();

        let value = event.target.value;

        // Replace , with . if number field
        if (this.props.type === 'number') value = value.replace(/,/g, '.');

        // If this input is numeric and value does not satisfy for actual assignment, return
        if (
            this.props.type === 'number' &&
            Utils.isValidInputNumber(value) === false
        ) {
            return;
        }

        // If this input is numeric and value is numeric, parse it as float
        /* this brokes decimal zeros. Cant put for example 40.05
    if (this.props.type === "number" && Utils.isNumeric(value)) {
      value = parseFloat(value);
    }
*/
        // Update state
        this.setState({
            value: value,
        });

        if (this.props.onInput) this.props.onInput({ ...event });

        console.log(
            'onInput: Name: ' +
                this.props.name +
                ', Type: ' +
                this.props.type +
                ', Value: ' +
                value
        );
    }

    onBlur(event) {
        event.preventDefault();

        let value = event.target.value;

        // Replace , with . if number field
        if (this.props.type === 'number' && Utils.isNumeric(value) === true)
            value = value.replace(/,/g, '.');

        if (this.props.type === 'number') {
            if (value === '') value = this.props.min;

            value = parseFloat(value);

            if (this.props.min && !this.props.max)
                value = Math.max(value, this.props.min);

            if (this.props.max && !this.props.min)
                value = Math.min(value, this.props.max);

            if (this.props.min && this.props.max)
                value = Math.min(
                    Math.max(value, this.props.min),
                    this.props.max
                );

            if (this.props.roundToZero === true)
                value = Utils.roundToZeroDec(value);

            if (this.props.roundToOne === true)
                value = Utils.roundToOneDec(value);

            if (this.props.roundToTwo === true)
                value = Utils.roundToTwoDec(value);

            if (this.props.roundToFour === true)
                value = Utils.roundToFourDec(value);
        } else if (
            this.props.type === 'number' &&
            Utils.isNumeric(value) === false
        ) {
            value = '';
        }

        this.setState({
            value: value,
        });

        event.target.value = value;

        if (this.props.onInput) this.props.onInput({ ...event });

        console.log(
            'onBlur: Name: ' +
                this.props.name +
                ', Type: ' +
                this.props.type +
                ', Value: ' +
                value
        );
    }

    render() {
        return (
            <p className="control">
                <input
                    className="input"
                    type="text"
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={() => {}}
                    onInput={this.onInput.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    placeholder={this.props.placeholder}
                />
            </p>
        );
    }
}

export default CalcField;
