// LangSelect.jsx
import React, { useState } from 'react';
import Select from 'react-select';

const LangSelect = ({
    className,
    locale,
    languages,
    handleLanguageChange,
    language,
}) => {
    const onChange = (selectedOption) => {
        if (selectedOption) {
            handleLanguageChange(selectedOption.code);
        }
    };

    return (
        <Select
            className={className}
            name="language"
            value={language === 'sv' ? '2' : '1'}
            placeholder={locale.dropdownPlaceholderLang}
            noOptionsMessage={() => locale.dropdownNoResults}
            options={languages}
            onChange={onChange}
        />
    );
};

export default LangSelect;
